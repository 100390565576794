import { gql, useQuery } from "@apollo/client";
import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import { Restaurant } from "../../components/restaurant";
import { CATEGORY_FRAGMENT, RESTAURANT_FRAGMENT } from "../../fragments";
import {
  restaurantsPageQuery,
  restaurantsPageQueryVariables,
} from "../../__generated__/restaurantsPageQuery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

const RESTAURANTS_QUERY = gql`
  query restaurantsPageQuery($input: RestaurantsInput!) {
    allCategories {
      ok
      error
      categories {
        ...CategoryParts
      }
    }
    restaurants(input: $input) {
      ok
      error
      totalPages
      totalResults
      results {
        ...RestaurantParts
      }
    }
  }
  ${RESTAURANT_FRAGMENT}
  ${CATEGORY_FRAGMENT}
`;

interface IFormProps {
  searchTerm: string;
}

export const Restaurants = () => {
  const [page, setPage] = useState(1);
  const { data, loading } = useQuery<
    restaurantsPageQuery,
    restaurantsPageQueryVariables
  >(RESTAURANTS_QUERY, {
    variables: {
      input: {
        page: page,
      },
    },
  });
  const onNextPageClick = () => setPage((current) => current + 1);
  const onPrevPageClick = () => setPage((current) => current - 1);
  const { register, handleSubmit, getValues } = useForm<IFormProps>();
  const history = useHistory();
  const onSearchSubmit = () => {
    const { searchTerm } = getValues();
    history.push({
      pathname: "/search",
      search: `?term=${searchTerm}`,
    });
  };
  return (
    <div>
      <Helmet>
        <title>Home | FooDelivery</title>
      </Helmet>
      <form
  onSubmit={handleSubmit(onSearchSubmit)}
  className="w-full py-40 flex items-center justify-center"
  style={{
    backgroundImage: `url(https://sky.iocky.com/i/2023/04/19/643f3e7d10ebe.jpeg)`,
    backgroundPosition: "center",
    backgroundSize: "cover",
  }}
>
  <div className="relative w-3/4 md:w-3/6">
    <input
      {...register("searchTerm", {
        required: true,
      })}
      type="Search"
      className="input rounded-md border-0 w-full pl-8"
      placeholder="Search restaurants..."
    />
    <button
      type="submit"
      className="absolute right-12 top-1/2 transform -translate-y-1/2 text-gray-500"
    >
      <FontAwesomeIcon icon={faSearch} />
    </button>
  </div>
</form>
      {!loading && (
        <div className="max-w-screen-2xl pb-20 mx-8 mt-8">
          <div className="flex justify-around max-w-xl mx-auto">
          {data?.allCategories.categories?.map((category) => (
            <Link key={category.id} to={`/category/${category.slug}`}>
              <div className="flex flex-col group items-center cursor-pointer">
                <div className="w-16 h-16 group-hover:bg-gray-100 rounded-full m-1 p-2">
                  <div
                    className="w-full h-full bg-cover"
                    style={{
                      backgroundImage: `url(${category.coverImg})`,
                       backgroundPosition: 'center',
                       backgroundSize: 'cover',
                    }}
                  ></div>
                </div>
                <span className="mt-1 text-sm text-center font-medium">
                  {category.name}
                </span>
              </div>
            </Link>
          ))}
          </div>

          <div className="grid mt-16 md:grid-cols-3 gap-x-5 gap-y-10">
            {data?.restaurants.results?.map((restaurant) => (
              <Restaurant
                key={restaurant.id}
                id={restaurant.id + ""}
                coverImg={restaurant.coverImg}
                name={restaurant.name}
                categoryName={restaurant.category?.name}
              />
            ))}
          </div>
          <div className="grid grid-cols-3 text-center max-w-md items-center mx-auto mt-10">
            {page > 1 ? (
              <button
                onClick={onPrevPageClick}
                className="focus:outline-none font-medium text-2xl"
              >
                &larr;
              </button>
            ) : (
              <div></div>
            )}
            <span>
              {page} of {data?.restaurants.totalPages}
            </span>
            {page !== data?.restaurants.totalPages && (
              <button
                onClick={onNextPageClick}
                className="focus:outline-none font-medium text-2xl"
              >
                &rarr;
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
