import { gql, useLazyQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Helmet } from "react-helmet-async";
import { useHistory, useLocation } from "react-router-dom";
import { Restaurant } from "../../components/restaurant";
import { RESTAURANT_FRAGMENT } from "../../fragments";
import {
  searchRestaurant,
  searchRestaurantVariables,
} from "../../__generated__/searchRestaurant";
import { Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";

const SEARCH_RESTAURANT = gql`
  query searchRestaurant($input: SearchRestaurantInput!) {
    searchRestaurant(input: $input) {
      ok
      error
      totalPages
      totalResults
      restaurants {
        ...RestaurantParts
      }
    }
  }
  ${RESTAURANT_FRAGMENT}
`;

interface IFormProps {
  searchTerm: string;
}

export const Search = () => {
  const location = useLocation();
  const history = useHistory();
  const [queryReadyToStart, { loading, data, called }] = useLazyQuery<
    searchRestaurant,
    searchRestaurantVariables
  >(SEARCH_RESTAURANT);
  const { register, handleSubmit, getValues } = useForm<IFormProps>();
  const [searchFocused, setSearchFocused] = useState(false);
  
  useEffect(() => {
    const [_, query] = location.search.split("?term=");
    if (!query) {
      return history.replace("/");
    }
    queryReadyToStart({
      variables: {
        input: {
          page: 1,
          query,
        },
      },
    });
  }, [history, location]);

  const onSearchSubmit = () => {
    const { searchTerm } = getValues();
    history.push({
      pathname: "/search",
      search: `?term=${searchTerm}`,
    });
  };

  const hasRestaurants =
    data?.searchRestaurant.restaurants?.length &&
    data?.searchRestaurant.restaurants?.length > 0;

    return (
      <div className="container mx-auto px-10">
        <Helmet>
          <title>Search | FooDelivery</title>
        </Helmet>
        <div className="bg-white py-4 px-8">
        <form
          onSubmit={handleSubmit(onSearchSubmit)}
          className="relative"
        >
          <input
            {...register("searchTerm", {
              required: true,
            })}
            onFocus={() => setSearchFocused(true)}
            onBlur={() => setSearchFocused(false)}
            type="Search"
            className={`w-full py-3 px-4 rounded-lg text-sm transition duration-300 ease-in-out border-2 pl-8 ${
              searchFocused
                ? "border-l-4 border-r-4 border-t-4 border-b-4 border-gradient-r-blue-purple animate-gradient-x"
                : "border-gray-300"
            } focus:outline-none focus:border-gradient-r-blue-purple focus:animate-gradient-x`}
            placeholder="Search restaurants..."
          />
          <button
            type="submit"
            className="absolute right-12 top-1/2 transform -translate-y-1/2 text-gray-500"
          >
            <FontAwesomeIcon icon={faSearch} />
          </button>
        </form>
      </div>
        {!loading && (
        <>
          <div className="grid mt-8 mb-10 md:grid-cols-3 gap-x-5 gap-y-10">
            {data?.searchRestaurant.restaurants?.map((restaurant) => (
              <Restaurant
                key={restaurant.id}
                id={restaurant.id + ""}
                coverImg={restaurant.coverImg}
                name={restaurant.name}
                categoryName={restaurant.category?.name}
              />
            ))}
          </div>
          {!hasRestaurants && (
            <div className="flex flex-col items-center justify-center mt-20">
              <span className="text-xl text-gray-600">
                No restaurants found for your search.
              </span>
            </div>
          )}
        </>
      )}
    </div>
  );
};
