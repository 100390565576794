import { useState } from "react";
import {
  VictoryTheme,
  VictoryChart,
  VictoryAxis,
  VictoryVoronoiContainer,
  VictoryLine,
  VictoryLabel,
} from "victory";
import { OrderParts } from "../__generated__/OrderParts";

interface IProps {
  orders: OrderParts[];
}

export const SalesChart = ({ orders }: IProps) => {
  const [interval, setInterval] = useState<"daily" | "weekly" | "monthly">(
    "daily"
  );

  const getIntervalLabel = (order: OrderParts) => {
    switch (interval) {
      case "daily":
        return new Date(order.createdAt).toLocaleTimeString("en-US", {
          hour: "numeric",
        });
      case "weekly":
        return new Date(order.createdAt).toLocaleDateString("en-US", {
          weekday: "short",
        });
      case "monthly":
        return `Week ${Math.ceil(
          new Date(order.createdAt).getDate() / 7
        )}`;
      default:
        return "";
    }
  };

  const getChartData = () => {
    const chartData: { x: string; y: number }[] = [];

    switch (interval) {
      case "daily":
        for (let i = 0; i < 24; i++) {
          chartData.push({
            x: `${i}:00`,
            y: 0,
          });
        }
        break;
      case "weekly":
        for (let i = 0; i < 7; i++) {
          chartData.push({
            x: new Date(1970, 0, i).toLocaleDateString("en-US", {
              weekday: "short",
            }),
            y: 0,
          });
        }
        break;
      case "monthly":
        const lastDay = new Date(
          new Date().getFullYear(),
          new Date().getMonth() + 1,
          0
        ).getDate();
        for (let i = 1; i <= lastDay; i += 7) {
          chartData.push({
            x: `Week ${Math.ceil(i / 7)}`,
            y: 0,
          });
        }
        break;
    }

    orders.forEach((order) => {
      const intervalLabel = getIntervalLabel(order);
      const dataPoint = chartData.find((data) => data.x === intervalLabel);
      if (dataPoint && order.total !== null && order.total !== undefined) {
        dataPoint.y += order.total;
      }
    });
    

    return chartData;
  };

  return (
    <div>
      <div className="flex items-center mb-4">
        <span className="font-medium mr-4">Interval:</span>
        <button
          className={`${
            interval === "daily" ? "bg-gray-800 text-white" : "bg-gray-300"
          } py-1 px-2 rounded mr-2`}
          onClick={() => setInterval("daily")}
        >
          Daily
        </button>
        <button
          className={`${
            interval === "weekly" ? "bg-gray-800 text-white" : "bg-gray-300"
          } py-1 px-2 rounded mr-2`}
          onClick={() => setInterval("weekly")}
        >
          Weekly
        </button>
        <button
          className={`${
            interval === "monthly" ? "bg-gray-800 text-white" : "bg-gray-300"
          } py-1 px-2 rounded`}
          onClick={() => setInterval("monthly")}
        >
          Monthly
        </button>
      </div>
      <VictoryChart
        theme={VictoryTheme.material}
        height={400}
        width={600}
        containerComponent={
          <VictoryVoronoiContainer
            labels={({ datum }) => `$${datum.y}`}
            labelComponent={
              <VictoryLabel dy={-7} style={{ fontSize: 10 }} />
            }
          />
        }
      >
        <VictoryAxis
          dependentAxis
          style={{
            axis: { stroke: "none" },
            grid: { stroke: "rgba(0, 0, 0, 0.2)" },
            ticks: { stroke: "rgba(0, 0, 0, 0.2)", size: 5 },
            tickLabels: { fontSize: 10, padding: 5 },
          }}
        />
        <VictoryAxis
          style={{
            axis: { stroke: "none" },
            grid: { stroke: "rgba(0, 0, 0, 0.2)" },
            ticks: { stroke: "rgba(0, 0, 0, 0.2)", size: 5 },
            tickLabels: { fontSize: 10, padding: 5 },
          }}
        />
        <VictoryLine data={getChartData()} />
      </VictoryChart>
    </div>
  );
};
