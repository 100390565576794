import { gql, useQuery } from "@apollo/client";
import React from "react";
import { Link, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { CATEGORY_FRAGMENT, RESTAURANT_FRAGMENT } from "../../fragments";
import { category, categoryVariables } from "../../__generated__/category";

const CATEGORY_QUERY = gql`
  query category($input: CategoryInput!) {
    category(input: $input) {
      ok
      error
      totalPages
      totalResults
      restaurants {
        ...RestaurantParts
      }
      category {
        ...CategoryParts
      }
    }
  }
  ${RESTAURANT_FRAGMENT}
  ${CATEGORY_FRAGMENT}
`;

interface ICategoryParams {
  slug: string;
}

export const Category = () => {
  const params = useParams<ICategoryParams>();
  const { data, loading } = useQuery<category, categoryVariables>(
    CATEGORY_QUERY,
    {
      variables: {
        input: {
          page: 1,
          slug: params.slug,
        },
      },
    }
  );

  const hasRestaurants =
    data?.category.restaurants?.length && data?.category.restaurants?.length > 0;

  return (
    <div className="container mx-auto px-10">
      <Helmet>
        <title>Category | FooDelivery</title>
      </Helmet>
      {!loading && (
        <div>
          {hasRestaurants ? (
            <>
              <div className="flex items-center mb-6">
                <div
                  className="w-16 h-16 bg-cover bg-center mr-4"
                  style={{
                    backgroundImage: `url(${data?.category.category?.coverImg})`,
                  }}
                ></div>
                <h1 className="text-3xl font-semibold">
                  {data?.category.category?.name}
                </h1>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                {data?.category.restaurants?.map((restaurant) => (
                  <div key={restaurant.id}>
                    <Link to={`/restaurants/${restaurant.id}`}>
                      <div
                        className="w-full h-64 bg-cover bg-center mb-3"
                        style={{
                          backgroundImage: `url(${restaurant.coverImg})`,
                        }}
                      ></div>
                      <h3 className="text-xl font-medium">
                        {restaurant.name}
                      </h3>
                      <span className="text-gray-500">
                        {restaurant.category?.name}
                      </span>
                      <p className="mt-2 text-gray-700">{restaurant.address}</p>
                    </Link>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <div className="flex flex-col items-center justify-center min-h-screen">
              <div
                className="w-16 h-16 bg-cover bg-center mb-4"
                style={{
                  backgroundImage: `url(${data?.category.category?.coverImg})`,
                }}
              ></div>
              <h2 className="text-2xl text-center font-semibold mb-2">
                {data?.category.category?.name}
              </h2>
              <p className="text-xl text-gray-600 text-center">
                Sorry, There are currently no restaurants available in this category.
                <br />
                We will do our best to find suitable merchants.
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
